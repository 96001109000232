@media screen and (min-width: 768px) {
  body {
    background-color: lightgrey;
  }
  .questionnaire-div {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #f1f1f1;

    .questionnaire-form {
      background-color: white;
      width: 100%;
      margin: 0rem 0rem 2rem 18.5rem;
      box-shadow: 1px 1px 3px 3px rgba(234, 234, 234, 0.5);
      padding-bottom: 10px;
      border-radius: 2px;

      h4 {
        color: #505b55;
      }

      .button-div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0px 20px 0px 20px;
      }

      .questions {
        display: flex;
        padding: 15px;
        border-color: #505b55;
        flex-direction: column;
        width: 100%;

        .question-types {
          display: flex;
          flex-direction: column;
          margin: 10px;
          padding: 10px 10px 20px 20px;
          border-radius: 8px;
          background-color: rgba(132, 203, 203, 0.47);
          box-shadow: 1px 1px 3px 3px white;
          align-items: flex-start;
          gap: 35px;

          .common-text {
            font-weight: bold;
          }

          .question-options {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            textarea {
              width: 50%;
              padding: 12px 20px;
              margin: 8px 0;
              display: inline-block;
              border: 1px solid #ccc;
              border-radius: 4px;
              box-sizing: border-box;

              &:focus-visible {
                border-color: #505b55;
              }
            }
          }
        }

        .topic-with-icon {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 15px;
          margin-bottom: 20px;

          .topic {
            display: flex;
            height: 25px;
            padding: 10px;
            background-color: #418585;
            border-radius: 50%;
            align-items: center;
          }
        }

        .question {
          display: flex;
          flex-direction: column;
          width: 90%;
          gap: 10px;
          align-items: flex-start;

          .question-value {
            font-weight: bold;
            gap: 20px;
            display: flex;
            flex-direction: row;
            color: #505b55;
          }
        }
      }

      .btn-submit {
        background-color: #FFFFFF;
        border: 1px solid blue;
        border-radius: 15px;
        box-sizing: border-box;
        color: blue;
        cursor: pointer;
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        margin: 0;
        outline: none;
        padding: 5px 15px 5px 15px;
        position: relative;
        text-align: center;
        text-decoration: none;
        touch-action: manipulation;
        transition: box-shadow .2s, -ms-transform .1s, -webkit-transform .1s, transform .1s;
        user-select: none;

        &:focus-visible {
          box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
          transition: box-shadow .2s;
        }

        &:active {
          background-color: #F7F7F7;
          border-color: #000000;
          transform: scale(.96);
        }

        &:hover {
          background-color: #F7F7F7;
          border-color: #000000;
          transform: scale(.96);
        }

      }
    }
  }
}

//input:checked + label {
//  color: #505b55;
//}
//input[type="checkbox"]:checked {
//  accent-color: black;
//  mix-blend-mode: multiply;
//  border-color: black;
//}
//
input[type="radio"] {
  height: 27px;
}


select {
  padding: 3px;
  width: 10%;
  font-size: 1.1rem;
  background-color: #fff;
  border: 1.5px solid #757070;
  color: #505b55;
  cursor: pointer;
}

select:focus-visible {
  border: 2px solid blue;
}


